const nodeData = [
  {
    id: 1,
    text:
      "The Road Not Taken by Robert Frost Two roads diverged in a yellow wood, And sorry I could not travel both And be one traveler, long I stood And looked down one as far as I could To where it bent in the undergrowth; And having perhaps the better claim.",
    dateTime: "27 April 2019, 2:04 pm"
  },
  {
    id: 2,
    text:
      "The Road Not Taken by Robert Frost Two roads diverged in a yellow wood, And sorry I could not travel both And be one traveler, long I stood And looked down one as far as I could To where it bent in the undergrowth; And having perhaps the better claim, Because it was ",
    dateTime: "27 April 2019, 2:04 pm"
  }
];

export default nodeData;
